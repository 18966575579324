<template>
<!-- 订单详情的订单信息tab子组件 -->
  <el-form :model="form"  label-width="130px" class="disabled" :disabled="true">
    <div class="orderInfo">
      <div class="col col4">
        <el-form-item label="采购订单号">
          <el-input v-model="form.formitem.orderCode"></el-input>
        </el-form-item>
      </div>
       <div class="col col4" >
        <el-form-item label="采购员">
          <el-input v-model="form.formitem.buyerName"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" >
        <el-form-item label="公司名称">
          <el-input v-model="form.formitem.companyName"></el-input>
        </el-form-item>
      </div>
    </div>
  </el-form>

</template>

<script>
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'ReOrderInformation',
  data () {
    return {
      form: {
        formitem: {}
      }
    }
  },
  mounted () {
    this.OrderInfor()
  },
  methods: {
    OrderInfor () { // 订单信息
      var orderCode = this.$route.query.orderCode
      requestForm('/api/order/order/getOrderDetailInfoByOrderCode?orderCode=' + orderCode, 'POST', '').then((response) => {
        if (response.code === '200') {
          this.form = response.data.ekkoInfo
          this.form.forEach(item => {
            this.form.formitem = item
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
